import React from 'react';
import { MdDone } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import styled from "styled-components";

const ResultDialog = ({ showResult }) => {
  return (
    <BoxOk>
      <OkMsg>
        <div className="container-modal-done">
          <div className="logo-close">
            <h5 onClick={() => showResult(false)}>
              Cerrar <AiOutlineClose className="icon-close" />
            </h5>
          </div>
          <div className="logo">
            <MdDone />
          </div>
          <h1>
            Consulta <br />
            <span>exitosa</span>
          </h1>
          <h4>
            Tu consulta ha sido realizada con éxito. <br /> Rebibirás una
            respuesta en breve. <br />
            Muchas gracias
          </h4>
        </div>
      </OkMsg>
    </BoxOk>
  )
}
export default ResultDialog


const BoxOk = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 22;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OkMsg = styled.div`
  width: 500px;
  height: 650px;
  background: #f2efe6;
  z-index: 22;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .container-modal-done {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .logo-close {
      position: absolute;
      right: 5%;
      top: 3%;
      color: #cd9746;
      h5 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 700;
        gap: 5px;
        cursor: pointer;
        .icon-close {
          font-size: 30px;
          font-weight: bold;
        }
      }
    }
    .logo {
      width: 120px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 999px;
      border: 7px solid #cd9746;
      svg {
        width: 100%;
        height: 100%;
        color: #cd9746;
      }
    }
    h1 {
      font-family: "Poppins", sans-serif;
      color: #000;
      font-size: 74px;
      line-height: 74px;
      font-weight: bold;
      text-align: center;
      margin-top: 1.2rem;
      span {
        font-size: 84px;
      }
    }
    h4 {
      text-align: center;
      font-family: "Poppins";
      font-weight: 100;
      margin-top: 1.2rem;
    }
  }
  @media screen and (max-width: 700px) {
    width: 100%;
    height: 100%;
  }
`;
